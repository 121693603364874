import React from 'react';
import styled from "styled-components";
import media from "../mixins/mixins.js";
import {RevealCascade} from "../components/Animate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faDribbble, faCodepen, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Links = styled.div`
  position: fixed;
  left:  var(--padding-sides);
  bottom: var(--padding-sides);;
  color: #161616;
  a {
    font-size: var(--font-lvl-2);
    line-height: 100%;
    margin-bottom: var(--spacing-SM);
    display: block;
    text-align: left;
    cursor: pointer;
    mix-blend-mode: difference;
    ${media.xxl`
      font-size: var(--font-lvl-3);
      margin-bottom: var(--spacing-MD);
  `}
  }
  .github:hover {
    color: #0366d6 !important;
  }
  .codepen:hover {
    color: #fcd000 !important;
  }
  .linkedin:hover {
    color: #0077b5 !important;
  }
  .dribbble:hover {
    color: #ea4c89 !important;
  }
`;

export default class SocialLinks extends React.Component {
  render() {
    return (
      <Links id="links" className={this.props.className}>
          <a href="https://github.com/bobbymarcus" target="_blank"  rel="noopener noreferrer" className="github"><FontAwesomeIcon icon={faGithub} /></a>
          <a href="https://codepen.io/robert-marcus" target="_blank"  rel="noopener noreferrer" className="codepen"><FontAwesomeIcon icon={faCodepen} /></a>
          <a href="https://www.linkedin.com/in/rsm23/" target="_blank"  rel="noopener noreferrer" className="linkedin"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="https://dribbble.com/bobbymarcus" target="_blank"  rel="noopener noreferrer" className="dribbble"><FontAwesomeIcon icon={faDribbble} /></a>
      </Links>
    );
  }
}
