import React from "react";
import $ from 'jquery';
import {MenuLink, MenuLinkGradient, MenuLinkHome} from './MenuLink.js';
import {RevealCascade} from "../Animate";
import SocialLinks from "../SocialLinks";
import "./Menu.scss";
export default class Menu extends React.Component {
  componentDidMount() {
    $(".menuBtn").click(function(){
      $("#menu").animate({ width: "toggle" });
      $(".menuOverlay").fadeToggle(500);
      $("#menu").css('transition', 'width 0s ease');
      $(".menuBtn span").toggleClass('bg-black');
      $("#links a").toggleClass('color-black');
      $("#progressBar div").toggleClass("hide-progress-bar");
      $(".menuBtn").toggleClass("no-blend");
    });
    // close menu by clicking outside of menu
    $(".menuOverlay").click(function(){
        $(".menuBtn").click();
        $('input[type="checkbox"]').prop('checked', false);
    });
  }
  render() {
    return (
      <React.Fragment>
      <div id="menu">
        <ul className="menuItems">
          <ul className="menuProject">
            <li className="home">
              {/* <MenuLinkHome to="/" linktext="Contact" /> */}
              <MenuLinkHome to="/" linktext="About" />
            </li>
            <RevealCascade>
              <p>Selected Work</p>
            </RevealCascade>
            <li className="rexyrace">
              <MenuLink to="/rexyrace" linktext="Rexy's Holiday Race" />
            </li>
            <li className="lifecoach">
              <MenuLink to="/lifecoach" linktext="Life Coach" />
            </li>
            <li className="scenestealers">
              <MenuLink to="/scenestealers" linktext="Scene Stealers" />
            </li>
            <li className="newyorkdrive">
              <MenuLinkGradient to="/newyorkdrive" linktext="New York Drive" />
            </li>
            <li className="rainbowhockey">
              <MenuLinkGradient to="/rainbowhockey" linktext="Rainbow Hockey" />
            </li>
            <li className="mbj">
             <MenuLink to="/mbj" linktext="Coach x MBJ" />
            </li>
            {/* <li className="mothersday">
              <MenuLink to="/mothersday" linktext="Mother's Day AR" />
            </li> */}
            <li className="signature">
              <MenuLink to="/signature" linktext="Art of Signature" />
            </li>
            <li className="isetan">
              <MenuLink to="/isetan" linktext='Isetan "The Stage"' />
            </li>
            <div className="menuBackground" />
          </ul>
        </ul>
        <SocialLinks />
      </div>
      <div className="menuOverlay"></div>
      </React.Fragment>
    );
  }
}
