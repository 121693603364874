import React from 'react';
import "./index.scss"

export class Bio extends React.Component {
  render() {
    return (
      <div id="aboutSection" className="difference">
        <p>
          Interaction Designer & Creative Technologist in NYC building innovative and engaging digital experiences. I specialize in product design, design systems, front-end development and motion design.
          My versatile skillset has enabled me to concept and deliver many interesting projects throughout my career so far, ranging from websites and iOS apps to arcade games and experiential installations.
          Currently at Rockstar Games.
        </p>
      </div>
    );
  }
}

class ResumeRow extends React.Component {
  render() {
    return (
      <div className="row">
        <h3>{this.props.company}</h3>
        <div className="resumeTitlesGroup">
          {this.props.children}
        </div>
      </div>
    );
  }
}
class ResumeTitle extends React.Component {
  render() {
    return (
      <div className="resumeTitle">
        <h4>{this.props.title}</h4>
        <p>{this.props.startDate} – {this.props.endDate}</p>
      </div>
    );
  }
}

export class Resume extends React.Component {
  render() {
    return (
      <div id="resumeSection">
        <section id="twoColumnGrid" className="resumeWrapper">
          <div className="section">
            <div className="column">
              <h1>Experience</h1>
            </div>
            <div className="column">
              <ResumeRow company={"Rockstar Games"} >
                <ResumeTitle
                  title={"Principal Interaction Designer"}
                  startDate={"Dec 2021"}
                  endDate={"Present"}
                />
                <ResumeTitle
                  title={"Senior Interaction Designer"}
                  startDate={"July 2020"}
                  endDate={"Dec 2021"}
                />
              </ResumeRow>
              <ResumeRow company={"Coach"} >
                <ResumeTitle
                  title={"Manager, Interaction Designer"}
                  startDate={"Jan 2018"}
                  endDate={"July 2020"}
                />
                <ResumeTitle
                  title={"UI/UX Designer (Contract)"}
                  startDate={"June 2017"}
                  endDate={"Jan 2018"}
                />
              </ResumeRow>
              <ResumeRow company={"Freelance"} >
                <ResumeTitle
                 title={"UI/UX Designer"}
                 startDate={"May 2016"}
                 endDate={"Jan 2018"}
                />
              </ResumeRow>
              <ResumeRow company={"The Advantage Co."} >
                <ResumeTitle
                title={"UI/UX Designer"}
                startDate={"May 2016"}
                endDate={"May 2017"}
                />
              </ResumeRow>
            </div>
          </div>
          <div className="section" id="">
            <div className="column">
              <h1>Skills</h1>
            </div>
            <div className="column">
              <div className="skillsColumn">
                <h3>Design</h3>
                <p>Figma/Sketch</p>
                <p>Principle</p>
                <p>Framer</p>
                <p>Spark AR</p>
                <p>Blender</p>
                <p>After Effects</p>
                <p>Photoshop</p>
                <p>Illustrator</p>

              </div>
              <div className="skillsColumn">
                <h3>Development</h3>
                <p>React</p>
                <p>Three.js</p>
                <p>JavaScript</p>
                <p>HTML/CSS</p>
                <p>SASS/LESS</p>
                <p>GSAP</p>
                <p>P5.js</p>
                <p>Git</p>
              </div>
            </div>
          </div>
        </section >
        <div id="nextProjectTrigger" />
      </div >

    );
  }
}

// export class Skills extends React.Component {
//   render() {
//     return (
//       <div id="resumeSection">
//         <section className="resumeWrapper">
//           <div className="section">
//             <div className="column">
//               <h1>Experience</h1>
//             </div>
//             <div className="column">
//               <ResumeRow company={"Rockstar Games"} >
//                 <ResumeTitle
//                   title={"Principal Interaction Designer"}
//                   startDate={"Dec 2021"}
//                   endDate={"Present"}
//                 />
//                 <ResumeTitle
//                   title={"Senior Interaction Designer"}
//                   startDate={"July 2020"}
//                   endDate={"Dec 2021"}
//                 />
//               </ResumeRow>
//               <ResumeRow company={"Coach"} >
//                 <ResumeTitle
//                   title={"Manager, Interaction Designer"}
//                   startDate={"Jan 2018"}
//                   endDate={"July 2020"}
//                 />
//                 <ResumeTitle
//                   title={"UI/UX Designer (Contract)"}
//                   startDate={"June 2017"}
//                   endDate={"Jan 2018"}
//                 />
//               </ResumeRow>
//               <ResumeRow company={"Freelance"} >
//                 <ResumeTitle
//                  title={"UI/UX Designer"}
//                  startDate={"May 2016"}
//                  endDate={"Jan 2018"}
//                 />
//               </ResumeRow>
//               <ResumeRow company={"The Advantage Co."} >
//                 <ResumeTitle
//                 title={"UI/UX Designer"}
//                 startDate={"May 2016"}
//                 endDate={"May 2017"}
//                 />
//               </ResumeRow>
//             </div>
//           </div>
//           <div className="section" id="">
//             <div className="column">
//               <h1>Skills</h1>
//             </div>
//             <div className="column">
//               <div className="skillsColumn">
//                 <h3>Design</h3>
//                 <p>Figma/Sketch</p>
//                 <p>Principle</p>
//                 <p>Framer</p>
//                 <p>Spark AR</p>
//                 <p>Blender</p>
//                 <p>After Effects</p>
//                 <p>Photoshop</p>
//                 <p>Illustrator</p>

//               </div>
//               <div className="skillsColumn">
//                 <h3>Development</h3>
//                 <p>React</p>
//                 <p>Three.js</p>
//                 <p>JavaScript</p>
//                 <p>HTML/CSS</p>
//                 <p>SASS/LESS</p>
//                 <p>GSAP</p>
//                 <p>P5.js</p>
//                 <p>Git</p>
//               </div>
//             </div>
//           </div>
//         </section >
//         <div id="nextProjectTrigger" />
//       </div >

//     );
//   }
// }

