import React from "react";
import {RevealNav, FadeIn} from "../Animate";

// css
import "./Nav.scss";


export default class Nav extends React.Component {
  componentDidMount() {
  }
  render() {
    return (
      <div id="navGrid">
        <div id="nav">
          <div className="navItems">
              <h1 className="title">
                <RevealNav>
                  {this.props.client}
                </RevealNav>
              </h1>

              <h1 className="year">
                <RevealNav>
                  {this.props.projectYear}
                </RevealNav>
              </h1>
            <div className="menuBtn">
              <FadeIn>  <input type="checkbox" />
                <div className="burger">
                  <span />
                  <span />
                </div>
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
