import { css } from 'styled-components';

const breakpoints = {
   sm: "max-width: 767px",
   md: "min-width: 768px",
   lg: "min-width: 1024px",
   xl: "min-width: 1920px",
   xxl: "min-width: 2560px",
   portrait: "orientation: portrait",
   touch: "hover: none"
}

export default Object.keys(breakpoints).reduce((acc, label) => {
   acc[label] = (...args) => css`
      @media (${breakpoints[label]}) {
         ${css(...args)};
      }
   `
   return acc
}, {})
