import React from 'react';
import ProgressBar from "react-scroll-progress-bar";

export default class ScrollProgressBar extends React.Component {
    render() {
        return (
            <div id="progressBar">
                <ProgressBar bgcolor={"var(--" + this.props.projectClass + ")"} height="1vh" />
            </div>
        );
    }
}