import React from 'react';
import styled from "styled-components";
import media from "../mixins/mixins.js";
import {Reveal} from "./Animate.js";


/*
___ ____ _  _ ___
 |  |___  \/   |
 |  |___ _/\_  |
*/

// regular text section
const Text = styled.div`
  grid-column: var(--gridSection-SM);
`;
// caption
export const Caption = styled.p`
  font-size: var(--font-lvl-1);
  text-align: center;
  grid-column: var(--gridSection-LG);
`;

export class TextSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Text>
          <Reveal>
            <p>{this.props.children}</p>
          </Reveal>
        </Text>
      </React.Fragment>
    );
  }
};


/*
_ _  _ ____ ____ ____ ____
| |\/| |__| | __ |___ [__
| |  | |  | |__] |___ ___]
*/
// Regular Image Sections
const Image = styled.div`
  display: grid;
  width: 100%;
  grid-column: var(--gridSection-LG);
  grid-row-gap: var(--spacing-SM);
  & img {
    width: 100%;
  }
`;
const ImageSlim = styled(Image)`
  grid-column: var(--gridSection-MD);
`;
export class ImageSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Image>
          <Reveal>
            <img src={"img/" + this.props.folder + "/" + this.props.src} />
            <Caption> {this.props.caption} </Caption>
          </Reveal>
        </Image>
      </React.Fragment>
    );
  }
};
export class ImageSectionSlim extends React.Component {
  render() {
    return (
      <React.Fragment>
      <ImageSlim>
        <Reveal>
          <img src={"img/" + this.props.folder + "/" + this.props.src} />
          <Caption> {this.props.caption} </Caption>
        </Reveal>
      </ImageSlim>
      </React.Fragment>
    );
  }
};



/*
_  _ _ ___  ____ ____
|  | | |  \ |___ |  |
 \/  | |__/ |___ |__|
*/
const Video = styled.div`
  display: grid;
  width: 100%;
  grid-column: var(--gridSection-LG);
  grid-row-gap: var(--spacing-SM);
  & video {
    width: 100%;
  }
`;
const VideoSlim = styled(Video)`
  grid-column: var(--gridSection-MD);
`;

export class VideoSection extends React.Component {
  render() {
    return (
      <React.Fragment>
      <Video>
        <Reveal>
          <video src={"img/" + this.props.folder + "/" + this.props.src} autoPlay muted playsInline loop />
          <Caption> {this.props.caption} </Caption>
        </Reveal>
      </Video>
      </React.Fragment>
    );
  }
};
export class VideoSectionSlim extends React.Component {
  render() {
    return (
      <React.Fragment>
        <VideoSlim>
          <Reveal>
            <video src={"img/" + this.props.folder + "/" + this.props.src} autoPlay muted playsInline loop />
            <Caption> {this.props.caption} </Caption>
          </Reveal>
        </VideoSlim>
      </React.Fragment>
    );
  }
};

export class VideoSectionSlimExternal extends React.Component {
  render() {
    return (
      <React.Fragment>
      <VideoSlim>
        <Reveal>
          <video src={this.props.src} autoPlay muted playsInline loop />
          <Caption> {this.props.caption} </Caption>
        </Reveal>
      </VideoSlim>
      </React.Fragment>
    );
  }
};
