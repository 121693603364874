import React from 'react';
import Styled from 'styled-components';
import { ScrollScene } from 'scrollscene'
import { gsap, Linear } from "gsap";
import media from "../mixins/mixins.js";
import Menu from "../components/Menu/Menu";
import Nav from "../components/Nav/Nav";
import NextProjectButton from "../components/NextProjectButton";
import ScrollProgressBar from "../components/ScrollProgressBar";


const Grid = Styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-row-gap: var(--space-between-sections);
  overflow-x: hidden;
  padding-bottom: var(--next-project-button-height);
  & a {
    grid-column: 7 / 10;
  }
  // ${media.xxl`
  //   padding-bottom: calc(var(--next-project-button-height) + 10rem);
  // `}

`;
const BackgroundColor = Styled.span`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
  ${media.portrait`
    min-height: unset;
  `}
  ${media.sm`
    height: 45rem;
  `}
  ${media.md`
    height: 42.5rem;
  `}
  ${media.lg`
    height: 55rem;
  `}
  ${media.xl`
    height: 64rem;
  `}
  ${media.xxl`
    height: 72rem;
  `}
  &.lifecoach, &.signature {
    ${media.sm`
       height: 60rem;
    `}
    ${media.md`
      height: 45rem;
    `}
    ${media.lg`
      height: 60rem;
    `}
    ${media.xl`
      height: 70rem;
    `}
  } 
  &.mbj,  &.isetan {
    ${media.sm`
       height: 55rem;
    `}
    ${media.md`
      height: 50rem;
    `}
    ${media.lg`
      height: 60rem;
    `}
    ${media.xl`
      height: 68rem;
    `}
    ${media.xxl`
      height: 75rem;
    `}
  }
  &.signature {
    ${media.sm`
      height: 55rem;
    `} 
}
`;
const NextProjectTrigger = Styled.span`
    width: 100%;
    height: 0rem;
    grid-column: var(--gridSection-SM);
    visibility: hidden;
    bottom: 0;
    left: 0;
`;

export default class ProjectWrapper extends React.Component {
  componentDidMount() {
    const trigger = document.querySelector('#backgroundColor')
    const parallax = gsap.timeline({ paused: true });
    parallax.to("#backgroundColor", 1, { transform: "translateY(-40vh)", ease: Linear.easeNone })
    const parallaxIntro = new ScrollScene({
      triggerElement: trigger, triggerHook: "0",
      duration: window.innerHeight * 2,
      gsap: {
        timeline: parallax,
      },
    })

    //  // skew on scroll
    //  {const section = document.querySelector("#grid")
    //  let currentPixel = window.pageYOffset

    //  const looper = function () {
    //    const newPixel = window.pageYOffset
    //    const diff = newPixel - currentPixel
    //    const speed = diff * .005

    //    if (speed > 1) {
    //      const speed = 1
    //    };
    //    if (speed < -1) {
    //      const speed = -1
    //    };

    //    section.style.transform = "skewY(" + speed + "deg)"

    //    currentPixel = newPixel

    //    requestAnimationFrame(looper)
    //  }
    //  setTimeout(function(){ looper() }, 100);}
  }
  render() {
    return (
      <React.Fragment>
        <section className={this.props.projectClass}>
          <ScrollProgressBar {...this.props} />
          <Menu />
          <Nav client={this.props.client} projectYear={this.props.projectYear} />
          <BackgroundColor id="backgroundColor" className={this.props.projectClass} />

          <Grid id="grid">

            {this.props.children}
            <NextProjectTrigger id="nextProjectTrigger" />
          </Grid>
          <NextProjectButton nextProjectLink={this.props.nextProjectLink} nextProjectName={this.props.nextProjectName} nextProjectColor={this.props.nextProjectClass} />


        </section>
      </React.Fragment>
    );
  }
}
