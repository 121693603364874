import React from 'react';
import "./index.scss"

export default class HomeHeader extends React.Component {
    render() {
        return (
            <div id="headerSection">
                <div>
                    <h2>Bobby Marcus</h2>
                    {/* <h3>Interaction Designer &<br />Creative Technologist</h3> */}
                    <span>
                        <h3>Interaction Designer &</h3>
                        <h3>Creative Technologist</h3>
                    </span>
                 
                </div>
            </div>
        );
    }
}