import React from 'react';
import { ScrollScene } from 'scrollscene'
import { gsap, Power2 } from "gsap";
import $ from 'jquery';
import HomeWrapper from "../components/Home/HomeWrapper";
import { Bio, Resume } from "../components/Home/AboutSections";
import HomeHeader from "../components/Home/HomeHeader";
import ScrollDown from "../components/ScrollDown";
import SocialLinks from "../components/SocialLinks";


var nextProjectName = "Rexy's Holiday Race",
  nextProjectClass = "rexyrace",
  nextProjectLink = "/rexyrace";

var menu = ".menuBtn span",
  social = "#links a",
  bg = "#homeGrid",
  text = "#resumeSection h1, #resumeSection p, #resumeSection h3, #resumeSection b, #aboutSection p",
  about = "#aboutSection, #resumeSection",
  ease = Power2.easeInOut;

export default class Home extends React.Component {
  componentDidMount() {
    // page title
    document.title = "Bobby Marcus | Portfolio";
    // change colors for about me section
    const aboutTrigger = document.querySelector('#aboutSection')
    const scrollDown = gsap.timeline({ paused: true });
    scrollDown.from(menu, .3, { background: "white", ease: ease }, 0)
      .to("canvas", .2, { opacity: "0", ease: ease }, 0)
      .to(".difference", .3, { mixBlendMode: "unset", ease: ease }, 0)
      .from(bg, .3, { background: "#161616", ease: ease }, 0)
      .from(text, .3, { color: "white", ease: ease }, 0)
      .from(about, .3, { opacity: "0", ease: ease }, 0)
      .from(social, .3, { color: "white", ease: ease }, 0)
      .to("#scrollDown", 0, { background: "rgba(255,255,255,1)", border: ".5rem solid rgba(255,255,255,0)", ease: ease, delay: .3 }, 0)
      .from("#scrollDown", .3, { filter: "invert()", ease: ease }, 0);

    const scrollDownTransition = new ScrollScene({
      triggerElement: aboutTrigger, triggerHook: ".5", reverse: true,
      gsap: {
        timeline: scrollDown,
      },
    })

    $(document).ready(function () {
      $('.has-animation').each(function (index) {
        $(this).delay($(this).data('delay')).queue(function () {
          $(this).addClass('animate-in');
        });
      });
    });
  }
  render() {
    return (
      <HomeWrapper nextProjectLink={nextProjectLink} nextProjectName={nextProjectName} nextProjectClass={nextProjectClass}>
        <HomeHeader />
        <Bio className="difference" />
        <Resume />
        <ScrollDown />
        <SocialLinks className="desktopOnly difference" />
      </HomeWrapper>
    );
  }
}
