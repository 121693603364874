import React from 'react';
import $ from "jquery";
import "./index.scss"

export default class ScrollDown extends React.Component {
    componentDidMount() {
        // Rotate on scroll
        $(window).scroll(function () {
            var theta = $(window).scrollTop() / 500 % Math.PI;
            $('#scrollDown').css({ transform: 'rotate(' + theta + 'rad)' });
        });
    }
    render() {
        return (
            <img id="scrollDown" className="spin difference" src="img/global/scroll.svg" />
        );
    }
}