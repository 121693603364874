import React from 'react';
import styled from "styled-components";
import media from "../../mixins/mixins.js";
import {Reveal} from "../Animate";


const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  top: 0%;
  display: grid;
  grid-row-gap: var(--spacing-LG);
  grid-column: var(--gridSection-MD);
  padding: var(--space-above-hero) 0 calc(16% - var(--spacing-XL));
    ${media.sm`
      // padding-bottom: 0;
      grid-column: var(--gridSection-LG);
    `}
  & #header {
    text-align: center;
  }
  & #introCopy {
    position: relative;
    width: 78%;
    margin: auto;
    padding: 0;
    text-align: left;
      ${media.sm`
        width: 73%;
      `}
  }
`;
export class IntroSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroContainer id="heroContainer">
        <h1 id="header">{this.props.projectName}</h1>
          <div id="introCopy">
            <Reveal>
              <h1>{this.props.children}</h1>
            </Reveal>
          </div>
        </HeroContainer>
      </React.Fragment>
    );
  }
};




const Hero = styled.div`
  grid-column: var(--gridSection-LG);
  z-index: 1;
  position: relative;
  width: 100%;
  & img {
    width:100%;
  }
  & video {
    width:100%
  }
`;
export class HeroImage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Hero className="heroDesktop">
          <Reveal>
            <img src={"img/" + this.props.projectClass + "/hero.png"} alt="hero" />
          </Reveal>
        </Hero>
        <Hero className="heroMobile">
          <Reveal>
            <img src={"img/" + this.props.projectClass + "/hero-mobile.png"} alt="hero" />
          </Reveal>
        </Hero>
      </React.Fragment>
    );
  }
};




const HeroSlim = styled(Hero)`
  & video, img {
    width: 60%;
    height: auto;
    object-fit: cover;
    margin: 0 20%;
    box-shadow: 0 50px 50px -50px rgba(0,0,0,.1);
    ${media.portrait`
      width: 80%;
      height: 80vw;
      margin: 0 10%;
    `}
  }
`;
export class HeroVideoSlim extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSlim>
          <Reveal>
            <video src={"img/" + this.props.projectClass + "/hero.mp4"} autoPlay muted playsInline loop />
          </Reveal>
        </HeroSlim>
      </React.Fragment>
    );
  }
};
export class HeroImageSlim extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSlim>
          <Reveal>
            <img src={"img/" + this.props.projectClass + "/hero.jpg"} alt="hero" />
          </Reveal>
        </HeroSlim>
      </React.Fragment>
    );
  }
};
