import React from "react";
import { ScrollScene } from 'scrollscene'
import { gsap, Power3, Power2, Linear } from "gsap";
import DelayLink from 'react-delay-link';
import styled from "styled-components";
import media from "../mixins/mixins.js";
import $ from "jquery";
import { useMediaQuery } from 'react-responsive';


// gsap animation that triggers when button is clicked
function nextProjectTransition() {
  var nextProjectAnim = gsap.timeline({ paused: true });
  nextProjectAnim.to("#nextProject", 0, { overflow: "hidden" }, 0)
    .to("#nextProjectLabel", .2, { opacity: "0", ease: Power2.easeInOut }, 0)
    .to("#progressBar", .2, { opacity: 0, ease: Power3.easeInOut }, 0)
    .to("#wrapper", .3, { top: top, height: "auto", gridGap: "0", ease: Power2.easeInOut, delay: .2 }, 0)
    .to("#nextProject", .3, { height: "100vh", ease: Power2.easeInOut, delay: .2 }, 0);
  nextProjectAnim.play();
}

// window.addEventListener("resize", function () {
//   const width = window.innerWidth;
// });


// when to trigger next project button to show
var triggerHook = 1;
const width = window.innerWidth;

if (width > 0) {  // mobile
  var height = "14rem";
  var duration = "25%";
  var top = "var(--nav-height)";
}
if (width > 767) {  // desktop
  var height = "20rem";
  var duration = "40%";
}
if (width > 1023) {  // desktop
  var top = "calc(var(--nav-height) + var(--space-above-hero) + var(--padding-sides))";
}
if (width > 2559) {  // XL
  var height = "32rem";
  var duration = "35%";
}


// const handleMediaQueryChange = () => {
//   // matches will be true or false based on the value for the media query
// }
// const isSmall = useMediaQuery({ minWidth: 768 });

// const Variables = () => {
//   const isLarge = useMediaQuery({ minWidth: 2560 });
//   const height = isLarge ? "20rem" : "32rem";
// }

// const Responsive = () => {
// }

// const Responsive = () => {
//   const isLarge = useMediaQuery({ minWidth: 2560 });
//   const height = isLarge ? "32rem" : "32rem";

//   const trigger = document.querySelector('#nextProjectTrigger')
//   const nextProjectTimeline = gsap.timeline({ paused: true });
//   nextProjectTimeline.to("#nextProject", 1, { height: height, pointerEvents: "all", ease: Linear.easeNone }, 0)
//     .from("#wrapper", 0.1, { autoAlpha: 0 }, 0);

//   const nextProject = new ScrollScene({
//     triggerElement: trigger, triggerHook: triggerHook,
//     duration: "40%",
//     gsap: {
//       timeline: nextProjectTimeline,
//     },
//   })
// };
export default class NextProjectButton extends React.Component {
    // handleResize = e => {
    //   this.setState({});
    //   console.log('App component: render()')
    // };
  componentDidMount() {
    // window.addEventListener("resize", this.handleResize);
    // make button appear once user reaches the end of the page
    const trigger = document.querySelector('#nextProjectTrigger')
    const nextProjectTimeline = gsap.timeline({ paused: true });
    nextProjectTimeline.to("#nextProject", 1, { height: height, pointerEvents: "all", ease: Linear.easeNone }, 0)
      .from("#wrapper", 0.1, { autoAlpha: 0 }, 0);

    const nextProject = new ScrollScene({
      triggerElement: trigger, triggerHook: triggerHook,
      duration: duration,
      gsap: {
        timeline: nextProjectTimeline,
      },
    })
  }
  // useEffect() {
  //   window.addEventListener("resize", this.handleResize);
  // }
  render() {
    return (
      <DelayLink id="nextProjectBtn" to={this.props.nextProjectLink} delay={500} clickAction={nextProjectTransition} replace={false}>
        <div id="nextProject" className={this.props.nextProjectColor}>
          <div id="wrapper">
            <p id="nextProjectLabel"> Next Project</p>
            <h3>
              {this.props.nextProjectName} </h3>
          </div>
        </div>
      </DelayLink>
    );
  }
}

// export class NextProjectButton2 extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       width: "",
//       nextProjectButtonHeight: height,
//       nextProjectButtonDuration: "0"
//     };
//   }
//   handleResize = e => {
//     const width = window.innerWidth;
//     const nextProjectButtonHeight = width > 767 ? "20rem" : width > 2559 ? "32rem" : "14rem";
//     const nextProjectButtonDuration = width > 2559 ? "35%" : "40%";
//     this.setState(prevState => {
//       return {
//         width,
//         nextProjectButtonHeight,
//         nextProjectButtonDuration
//       };
//     });
//     console.log(nextProjectButtonHeight)
//   };

//   componentDidMount() {
//     window.addEventListener("resize", this.handleResize);
//   }
//   componentWillUnmount() {
//     window.removeEventListener("resize", this.handleResize);
//   }
//   render() {
//     return (
//       <NextProjectButton {...this.props} height={this.state.nextProjectButtonHeight} />
//     );
//   }
// }


