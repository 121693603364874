import React from 'react';
import Menu from "../Menu/Menu";
import NextProjectButton from "../NextProjectButton";
// import {NextProjectButton2} from "../NextProjectButton";
import { gsap, Power2 } from "gsap";
import "./HomeWrapper.scss"

function cursor() {
  const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const canvasTag = document.querySelector("canvas")
  canvasTag.width = window.innerWidth
  canvasTag.height = window.innerHeight
  const context = canvasTag.getContext("2d")
  let aimX = null
  let aimY = null
  let currentX = null
  let currentY = null
  let i = 0
  const images = ["img/global/image7.svg"].map(src => {
    const image = document.createElement("img")
    image.src = src
    return image
  })
  document.addEventListener("mousemove", function (event) {
    aimX = event.pageX
    aimY = event.pageY
    if (currentX === null) {
      currentX = event.pageX
      currentY = event.pageY
    }
  })
  const draw = function () {
    if (currentX) {
      if (images[i].complete) {
        context.drawImage(images[i], currentX - 200, currentY - 200)
      }
      currentX = currentX + (aimX - currentX) * 0.025
      currentY = currentY + (aimY - currentY) * 0.025
    }
    requestAnimationFrame(draw)
  }
  if (!mobileDevice) {
    window.addEventListener('resize', function () {
      document.querySelector("canvas").setAttribute("height", window.innerHeight);
      document.querySelector("canvas").setAttribute("width", window.innerWidth);
    });
  };
  draw()
}


export default class HomeWrapper extends React.Component {
  componentDidMount() {
    cursor();

    var header1 = document.querySelector('#headerSection h2:nth-child(1)')
    var header2 = document.querySelector('#headerSection span h3:nth-child(1)')
    var header3 = document.querySelector('#headerSection span h3:nth-child(2)')
    var ease = Power2.easeOut
    var easeOut = Power2.easeOut

    var headerIntro = gsap.timeline({ paused: true });
    headerIntro.from(header1, .3, { opacity: "0", transform: "translateY(5vh)", ease: ease, delay: .3 }, 0)
      .from(header2, .3, { opacity: "0", transform: "translateY(5vh)", ease: ease, delay: .45 }, 0)
      .from(header3, .3, { opacity: "0", transform: "translateY(5vh)", ease: ease, delay: .6 }, 0)
      .from(".menuBtn", .4, { autoAlpha: "0", transform: "translateX(-8vw)", ease: easeOut }, 1)
      .from("#links", .4, { autoAlpha: "0", left: "-1.5%", ease: easeOut }, 1)
      .from("#scrollDown", .4, { autoAlpha: "0", right: "-7.5%", ease: easeOut }, 1)
      .from("canvas", .4, { opacity: "0", ease: ease }, 1);
    headerIntro.play();
  }
  render() {
    return (
      <>
        <Menu />
        <canvas />
        <div id="homeGrid" className="has-animation animation-ltr">
          {this.props.children}
        </div>
        <div id="navGrid" style={{ pointerEvents: 'none' }}>
          <div id="nav" className="homeNav">
            <div className="navItems">
              <div className="menuBtn difference">
                <input type="checkbox" />
                <div className="burger">
                  <span className="has-animation-fadein" />
                  <span className="has-animation-fadein" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <NextProjectButton nextProjectLink={this.props.nextProjectLink} nextProjectName={this.props.nextProjectName} nextProjectColor={this.props.nextProjectClass} />
      </>
    );
  }
}
